import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import html2canvas from 'html2canvas'

/**
 * Certifi 证书海报
 */
const Certifi = (props) => {
    const { className, background, top, title, header, subheader, meta, beforecontent, content, subcontent, name, description, subdescription, action } = props;

    const poster = useRef(null)

    const formatDate = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${year}-${month}-${day}-${hours}-${minutes}`
    }

    const saveImg = async () => {
        const element = poster.current
        const options = {
            scrollY: 0,
            scrollX: 0,
            scale: 2,
            dpi: 1000,
            allowTaint: false,
            useCORS: true,
            borderRadis: 10,
            backgroundColor: '#fff',
        }
        const canvas = await html2canvas(element, options)
        const dataUrl = canvas.toDataURL('image/png')

        // 创建并下载 PNG 图片
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = formatDate(new Date())+'.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    
    return (
        <div className='h-certifi'>
            <div ref={poster} className={`${className} h-card tracking-wider flex flex-col bg-cover px-12 pb-6 w-full box-border object-fill font-sans`} style={{ backgroundImage: `url(${background})` }}>
                {/* top */}
                <div className='h-top mt-4'>
                    <img className='h-20' src={top}></img>
                </div>
                {/* title */}
                <div className=' text-white text-xl mt-2'>
                    {title.map(item => <div>{item}</div>)}
                </div>
                {/* header */}
                <div className=' mt-20 text-7xl font-bold text-center text-[#1a3063]'>{header}</div>
                {/* subheader */}
                <div className=' mt-2 text-2xl text-center text-[#1a3063]'>{subheader}</div>
                {/* meta */}
                <div className='mt-8 text-3xl flex flex-col items-center gap-2 font-semibold text-center'>
                    {meta.map(item => <div>{item}</div>)}
                </div>
                {/* beforecontent */}
                <div className=' mt-6 font-semibold text-2xl'>{beforecontent}</div>
                {/* content */}
                <div className=' mt-10 text-6xl font-semibold text-center text-[#1a3063]'>{content}</div>
                {/* subcontent */}
                <div className=' mt-10 font-semibold text-2xl'>{subcontent}</div>
                {/* name */}
                <div className=''>
                    <img className='h-28' src={name}></img>
                </div>
                <div className='flex justify-between'>
                    {/* description */}
                    <div className='flex flex-col items-center'>
                        {description.map(item => <div>{item}</div>)}
                    </div>
                    {/* subdescription */}
                    <div className='flex flex-col items-center'>
                        {subdescription.map(item => <div>{item}</div>)}
                    </div>
                </div>
                {/* action */}
                <div className=' my-10 text-2xl'>{action}</div>
            </div>
            <div className='mt-6'>
                <div onClick={saveImg} className='bg-primary text-white border px-3 py-2 cursor-pointer rounded-md w-auto inline-flex justify-center items-center'>
                    下载证书
                </div>
            </div>
        </div>
    )
}

Certifi.defaultProps = {};

Certifi.propTypes = {
    /**
     * className
     */
    className: PropTypes.string,
    /**
     * background
     */
    background: PropTypes.string,
    /**
     * top
     */
    top: PropTypes.string,
    /**
     * title
     */
    title: PropTypes.array,
    /**
     * header
     */
    header: PropTypes.string,
    /**
     * subheader
     */
    subheader: PropTypes.string,
    /**
     * meta
     */
    meta: PropTypes.array,
    /**
     * beforecontent
     */
    beforecontent: PropTypes.string,
    /**
     * content
     */
    content: PropTypes.string,
    /**
     * subcontent
     */
    subcontent: PropTypes.string,
    /**
     * name
     */
    name: PropTypes.string,
    /**
     * description
     */
    description: PropTypes.array,
    /**
     * subdescription
     */
    subdescription: PropTypes.array,
    /**
     * action
     */
    action: PropTypes.string,
};

export default Certifi;
